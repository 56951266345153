import storePageMessages from 'messages/storePages';
import React from 'react';
import { useIntl } from 'react-intl';
import LocationLink from './components/LocationLink';
import StoresSidebar from './components/StoresSidebar';
import { LocationGrid } from './styles';

const StoreCountriesView = ({ countries = [] }) => {
  const intl = useIntl();
  return (
    <StoresSidebar breadcrumbSections={[{ text: intl.formatMessage(storePageMessages.allCountries), url: '' }]}>
      <LocationGrid>
        {countries.map(country => (
          <LocationLink
            dataTestId='country-selector'
            locationName={country.name}
            number={country.amount}
            flag={country.countryCode}
            key={country.name}
            url={country.countryUrl}
          />
        ))}
      </LocationGrid>
    </StoresSidebar>
  );
};

export default StoreCountriesView;
