import { grid, IconMap, Flex, Typography, Button } from '@aceandtate/ds';
import storePageMessages from 'messages/storePages';
import Link from 'next/link';
import { paths } from 'paths';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Spacer } from 'views/Stores/styles';
import InfoCard from './InfoCard';
import * as Styles from './styles';

const StoresSidebar = ({ location = null, children, breadcrumbSections = [] }) => {
  return (
    <Styles.StyledFlex>
      <Styles.SidebarContainer>
        <Styles.SpacedBreadcrumb sections={breadcrumbSections} />
        <Styles.SidebarTitle>
          <FormattedMessage
            {...storePageMessages.sidebarTitle}
            values={{
              aceandtate: <span style={{ whiteSpace: 'nowrap' }}>Ace & Tate</span>
            }}
          />
          {location && (
            <>
              {' '}
              <FormattedMessage
                {...storePageMessages.locationChunk}
                values={{
                  location: (
                    <Styles.SidebarTitle serif forwardedAs='span'>
                      {location}
                    </Styles.SidebarTitle>
                  )
                }}
              />
            </>
          )}
        </Styles.SidebarTitle>
        <Spacer paddingTop={grid[40]}>
          <Flex alignItems='center' gap={grid[8]}>
            <IconMap fontSize='22px' />
            <Link href={paths.storesMap} style={{ textUnderlineOffset: '5px' }}>
              <Button variant='link' data-testid='switch-to-map-button'>
                <Typography>
                  <FormattedMessage {...storePageMessages.switchToMapView} />
                </Typography>
              </Button>
            </Link>
          </Flex>
        </Spacer>
        <Styles.StyledShowOnTablet>
          <InfoCard />
        </Styles.StyledShowOnTablet>
      </Styles.SidebarContainer>
      {children}
    </Styles.StyledFlex>
  );
};

export default StoresSidebar;
