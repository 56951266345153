import React, { useContext } from 'react';
import Image from 'next/image';
import storePageMessages from 'messages/storePages';
import { FormattedMessage } from 'react-intl';
import * as Styles from './styles';
import { ServicesContext } from 'services/context';
import { Button } from '@aceandtate/ds';
import Link from 'next/link';
import { paths } from 'paths';

const InfoCard = () => {
  const context = useContext(ServicesContext);
  return (
    <Styles.CardBackground>
      <Styles.CardText>
        {context.locale.country !== 'GB' ? (
          <FormattedMessage {...storePageMessages.infoCardBody} />
        ) : (
          <FormattedMessage {...storePageMessages.infoCardBodyGB} />
        )}
        <Link href={paths.bookAppointment} style={{ display: 'block' }}>
          <Button size='small' style={{ marginTop: '12px' }} color='primary'>
            <FormattedMessage {...storePageMessages.bookCTA} />
          </Button>
        </Link>
      </Styles.CardText>
      <Image
        src='/static/images/marketing/stores/eye-test.jpeg'
        width={504}
        height={392}
        style={{
          maxWidth: '100%',
          height: 'auto',
          objectFit: 'cover'
        }}
        alt='Ace & Tate | Eye test room'
      />
    </Styles.CardBackground>
  );
};

export default InfoCard;
