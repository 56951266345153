import { brandColors, breakpointRules, duration, easing, grid } from '@aceandtate/ds';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

export const LocationGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-column-gap: 40px;
  padding: ${grid[24]};
  overflow: auto;
  @media ${breakpointRules.tablet} {
    height: 100%;
  }
  @media ${breakpointRules.laptop} {
    grid-template-columns: 1fr 1fr;
    padding: ${grid[60]};
  }
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(320px, 100%), 1fr));
  width: 100%;
  grid-gap: 20px;
  padding: ${grid[24]};
  overflow: auto;
  height: 100%;
  @media ${breakpointRules.laptop} {
    padding: 64px;
  }
`;

type SpacerProps = {
  children: ReactNode;
  as?: React.ElementType;
  className?: string;
} & Pick<
  CSSProperties,
  | 'margin'
  | 'marginLeft'
  | 'marginRight'
  | 'marginTop'
  | 'marginBottom'
  | 'padding'
  | 'paddingLeft'
  | 'paddingRight'
  | 'paddingTop'
  | 'paddingBottom'
>;

export const Spacer = styled.div(({ children: _children, as: _as, className: _className, ...props }: SpacerProps) => {
  return {
    ...props
  };
});

export const SearchAdornment = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  padding: ${grid[4]};

  font-size: ${grid[24]};
  color: ${brandColors.dark75};
  transition: color ${duration.medium} ${easing.out};

  &:hover {
    color: ${brandColors.accent};
  }
`;
