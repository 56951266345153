import { brandColors, breakpointRules, grid } from '@aceandtate/ds';
import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: 2px solid ${brandColors.dark10};
  max-height: 120px;
  display: flex;
  align-items: center;
  gap: ${grid[8]};
  position: relative;
  cursor: pointer;
  padding: ${grid[24]} 0px;
  @media ${breakpointRules.tablet} {
    padding: ${grid[40]} 0px;
  }
  :hover {
    ::after {
      transform: scaleX(1);
    }
  }
  ::after {
    transition: all 0.3s ease-in-out;
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    transform: scaleX(0);
    transform-origin: left;
    background-color: ${brandColors.accent};
    bottom: -2px;
  }
`;
