import { Typography, brandColors, Flex, grid } from '@aceandtate/ds';
import ConditionalLink from 'components/ConditionalLink';
import Flag from 'components/Flag';
import React from 'react';
import { Container } from './styles';

const LocationLink = ({ locationName, number, flag = null, url = null, dataTestId = '' }) => {
  return (
    <ConditionalLink href={url} prefetch={false}>
      <Container data-testid={dataTestId}>
        {flag && (
          <Flex style={{ marginRight: grid[8] }}>
            <Flag iso={flag} size='large' />
          </Flex>
        )}
        <Typography fontWeight='bold' variant='bodyL'>
          {locationName}
        </Typography>
        <Typography fontWeight='bold' color={brandColors.dark30}>
          ({number})
        </Typography>
      </Container>
    </ConditionalLink>
  );
};

export default LocationLink;
